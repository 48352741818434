@font-face {
    font-family: Exo;
    src: url(./assets/fonts/Exo-VariableFont_wght.ttf);
}



* {
    margin: 0;
    padding: 0;
    font-family: 'Exo';
}


body {
    overflow-x: hidden;
    background-color: #fafafa;
}

.ButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: -24px;
}