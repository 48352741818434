.date {
    background-color: none;
    height: 58px;
    width: 100%;
    border: 1px solid #c4c4c4;
    border-radius: 100px;
    padding-left: 8px;
    /* color: #/; */
    cursor: pointer
}

.PhoneInput {
    width: 104%;
    height: 40px;
    border: 1px solid #c4c4c4;
    padding: 8px;
    border-radius: 5px;
}

.border-radius-2 .modal-content {
    border-radius: 24px;
    /* border: none; */
}

.modal-header {
    background-color: #55e6c1;
    color: white;
    border-radius: 24px 24px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.PhoneInputInput {
    border: none;
    color: #000000;
}