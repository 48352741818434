.linksContainer {
    color: white;
    /* text-align: center; */
    margin-top: 32px;
    margin-left: 16px
}

.verticalLine {
    width: 100%;
    height: 3px;
    background-color: white;
    opacity: 8%;

}

.copyRight {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: black;
    height: 40px;
}

.logo2 {
    margin: 0;
    width: 200px;
    height: 60px;
    background-image: url('../../assets/images/elitepunters.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.SocialMediaIcons {
    color: white;
    margin-right: 16px;
    cursor: pointer
}