.signinText {
    margin: 0;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    /* margin-left: 8px; */
    margin-right: 16px;
    color: white;
}

@media (max-width: 762px) {
    .signinText {
        margin-left: 8px;
        margin-bottom: 8px;
    }
}