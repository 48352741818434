.typesBadge p {
    margin: 0;
    letter-spacing: 0.05rem;
    background-color: #55e6c1;
    border-radius: 100px;
    padding: 8px 16px 8px 16px;
}

.types {
    display: flex;
    flex-direction: row;
}

.types .typesBadge {
    margin-left: 16px;
    margin-bottom: 10px;
    /* padding: 12px; */
    cursor: pointer;
    font-size: 16px;

}