.newsContainer {
    overflow-x: scrollpx;
    display: flex;
    flex-direction: row;
}

.mobileNewsContainer {
    overflow-x: scroll;
    display: flex;
    text-align: center;
    flex-direction: column;
    /* height: 50vh; */
}

.newsContainer::-webkit-scrollbar {
    display: none;
    /* for Chrome, Safari, and Opera */
}

.newsTitle {
    font-size: 16px;
    margin-bottom: 12px;
    line-height: 1.3px;
    height: 42px;
    text-align: justify;
}

.newsDetailsContainer {
    width: 400px;
    /* height: 45vh; */
    background-color: white;
    margin-left: 8px;
    padding: 8px;
    border-radius: 20px;
    text-align: center;
}